import React, { useState } from "react";
import { Typography, TextField } from "@material-ui/core";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { sendEmailOtp } from "../../../redux/action";

import { StepLabel, CustomButton } from "../../../../components";
import { validateEmail } from "../../../../utils/functions/validateEmail";
import styles from "../../styles";

const UpdateEmail = props => {
  const { sendEmailOtp, profile, history } = props;

  // Regex email
  // const pattern = /(.+)@(.+){2,}\.(.+){2,}/;

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const handleValidateEmail = value => {
    const validation = validateEmail(value);

    if (validation) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const handleEmail = () => {
    sendEmailOtp({ email: email.toLowerCase(), fullName: profile.fullName });
  };

  return (
    <div style={{ backgroundColor: "#fff", ...styles.container }}>
      <StepLabel
        title="Update email"
        onClick={() => history.back()}
        containerStyles={{ margin: "16px 16px 22px" }}
      />
      <div style={{ margin: "0 16px" }}>
        <Typography
          color="textPrimary"
          style={{ ...styles.baseFont, ...styles.inputLabel }}
        >
          New email
        </Typography>
        <TextField
          variant="outlined"
          placeholder="example@email.com"
          fullWidth
          value={email}
          onChange={e => setEmail(e.target.value)}
          onBlur={e => handleValidateEmail(e.target.value)}
          error={emailError}
          helperText={emailError && "Please enter a valid email format"}
          inputProps={{
            style: {
              ...styles.baseFont,
              padding: "12px 16px",
              color: "#333"
            }
          }}
          FormHelperTextProps={{
            style: { ...styles.baseFont, ...styles.errorText }
          }}
        />
      </div>
      <div style={styles.btnContainer}>
        <CustomButton
          text="Next"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={emailError || email.length === 0}
          onClick={handleEmail}
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    otp: state.goal.otp,
    profile: state.goal.profile
  }),
  { sendEmailOtp }
);

export default compose(
  withRouter,
  withConnect
)(UpdateEmail);
