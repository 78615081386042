import {
  GET_GOAL_REQUEST,
  GET_GOAL_SUCCESS,
  GET_GOAL_FAILURE,
  GET_GOAL_PERIODS_REQUEST,
  GET_GOAL_PERIODS_SUCCESS,
  GET_GOAL_PERIODS_FAILURE,
  GET_INSTALLMENT_REQUEST,
  GET_INSTALLMENT_SUCCESS,
  GET_INSTALLMENT_FAILURE,
  GOAL_CREATION_REQUEST,
  GOAL_CREATION_SUCCESS,
  GOAL_CREATION_FAILURE,
  GET_CUSTOMER_GOALS_REQUEST,
  GET_CUSTOMER_GOALS_SUCCESS,
  GET_CUSTOMER_GOALS_FAILURE,
  GET_CUSTOMER_GOAL_DETAIL_REQUEST,
  GET_CUSTOMER_GOAL_DETAIL_SUCCESS,
  GET_CUSTOMER_GOAL_DETAIL_FAILURE,
  GET_NEWS_FEED_REQUEST,
  GET_NEWS_FEED_SUCCESS,
  GET_NEWS_FEED_FAILURE,
  GET_ONBOARDING_REQUEST,
  GET_ONBOARDING_SUCCESS,
  GET_ONBOARDING_FAILURE,
  GET_ANNUAL_INCOME_REQUEST,
  GET_ANNUAL_INCOME_SUCCESS,
  GET_ANNUAL_INCOME_FAILURE,
  GET_BUSINESS_NATURE_REQUEST,
  GET_BUSINESS_NATURE_SUCCESS,
  GET_BUSINESS_NATURE_FAILURE,
  GET_COUNTRY_REQUEST,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAILURE,
  GET_MARITAL_STATUS_REQUEST,
  GET_MARITAL_STATUS_SUCCESS,
  GET_MARITAL_STATUS_FAILURE,
  GET_OCCUPATION_REQUEST,
  GET_OCCUPATION_SUCCESS,
  GET_OCCUPATION_FAILURE,
  GET_RACE_REQUEST,
  GET_RACE_SUCCESS,
  GET_RACE_FAILURE,
  GET_STATE_REQUEST,
  GET_STATE_SUCCESS,
  GET_STATE_FAILURE,
  GET_SOURCE_OF_FUND_REQUEST,
  GET_SOURCE_OF_FUND_SUCCESS,
  GET_SOURCE_OF_FUND_FAILURE,
  GET_SOURCE_OF_INCOME_REQUEST,
  GET_SOURCE_OF_INCOME_SUCCESS,
  GET_SOURCE_OF_INCOME_FAILURE,
  GET_SUPPORTING_DOCS_REQUEST,
  GET_SUPPORTING_DOCS_SUCCESS,
  GET_SUPPORTING_DOCS_FAILURE,
  GET_TRX_HISTORY_REQUEST,
  GET_TRX_HISTORY_SUCCESS,
  GET_TRX_HISTORY_FAILURE,
  GET_PPG_TOKEN_REQUEST,
  GET_PPG_TOKEN_SUCCESS,
  GET_PPG_TOKEN_FAILURE,
  GET_WITHDRAWAL_BANK_LIST_REQUEST,
  GET_WITHDRAWAL_BANK_LIST_SUCCESS,
  GET_WITHDRAWAL_BANK_LIST_FAILURE,
  GET_WITHDRAWAL_BANK_ACCOUNT_REQUEST,
  GET_WITHDRAWAL_BANK_ACCOUNT_SUCCESS,
  GET_WITHDRAWAL_BANK_ACCOUNT_FAILURE,
  DEPOSIT_REQUEST,
  DEPOSIT_SUCCESS,
  DEPOSIT_FAILURE,
  WITHDRAWAL_REQUEST,
  WITHDRAWAL_SUCCESS,
  WITHDRAWAL_FAILURE,
  ECDD_REQUEST,
  ECDD_SUCCESS,
  ECDD_FAILURE,
  SEND_OTP_EMAIL_REQUEST,
  SEND_OTP_EMAIL_SUCCESS,
  SEND_OTP_EMAIL_FAILURE,
  VERIFY_OTP_EMAIL_REQUEST,
  VERIFY_OTP_EMAIL_SUCCESS,
  VERIFY_OTP_EMAIL_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  SEND_CONTACT_MESSAGE_REQUEST,
  SEND_CONTACT_MESSAGE_SUCCESS,
  SEND_CONTACT_MESSAGE_FAILURE,
  SEND_FEEDBACK_REQUEST,
  SEND_FEEDBACK_SUCCESS,
  SEND_FEEDBACK_FAILURE,
  CHECK_ECDD_STATUS_REQUEST,
  CHECK_ECDD_STATUS_SUCCESS,
  CHECK_ECDD_STATUS_FAILURE,
  SMS_OTP_REQUEST,
  SMS_OTP_SUCCESS,
  SMS_OTP_FAILURE,
  VERIFY_OTP_SMS_SUCCESS,
  VERIFY_OTP_SMS_FAILURE,
  VERIFY_OTP_SMS_REQUEST,
  SET_SAVING_TYPE,
  SET_SELECTED_GOAL,
  SET_GENERAL_SAVING_DETAILS,
  RESET_CATEGORY_SAVING,
  RESET_GENERAL_SAVING,
  SET_SAVING_GOAL_DETAILS,
  SET_PROFILE_INFO,
  SET_BANK_INFO,
  DELETE_SAVING_GOAL_FORM,
  SHOW_ERROR,
  HIDE_ERROR,
  SET_USER,
  NEW_USER,
  OLD_USER,
  ACTIVATE_AUTO_DEPOSIT,
  SET_FULL_REDEMPTION,
  CLEAR_SMS_OTP,
  SET_IS_LOADING,
  SET_REJECT_ALERT,
  CHECK_GOAL_NAME_REQUEST,
  CHECK_GOAL_NAME_SUCCESS,
  CHECK_GOAL_NAME_FAILURE,
  SET_GOAL_IS_EXIST,
  GET_GOAL_SUMMARY_REQUEST,
  GET_GOAL_SUMMARY_SUCCESS,
  GET_GOAL_SUMMARY_FAILURE,
  GET_GOAL_INTEREST_REQUEST,
  GET_GOAL_INTEREST_SUCCESS,
  GET_GOAL_INTEREST_FAILURE,
  SET_FAILURE_ALERT,
  SET_SHOW_ERROR,
  CHECK_WITHDRAWAL_MAX_AMOUNT_REQUEST,
  CHECK_WITHDRAWAL_MAX_AMOUNT_SUCCESS,
  CHECK_WITHDRAWAL_MAX_AMOUNT_FAILURE,
  RESET_DATA,
  CLEAR_ERROR,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  SET_IS_FIRST_OPEN_APP,
  CREATE_GOAL_RSP,
  GET_CAMPAIGN,
  GET_CAMPAIGN_FAILED,
  GET_CAMPAIGN_SUCCESS,
  SET_CIF_PLAN_ID,
  GET_WITHDRAWAL_RESULT,
  RESET_MAX_AMOUNT,
  RESET_CUSTOMER_GOALS,
  RESET_PROFILE,
} from "./constant";
import { SET_PROFILE_INFOV2 } from "../getStarted/redux/constant";

const initialState = {
  loading: false,
  error: null,
  isModalOpen: false,
  goalList: [],
  goalPeriodList: [],
  installment: null,
  customerGoals: null,
  customerGoalDetail: null,
  generalSavingDetails: null,
  savingGoalDetails: null,
  selectedGoal: null,
  stepNumber: 0,
  savingType: undefined, // GENERAL, CATEGORY
  isNewUser: NEW_USER,
  errorMessage: "",
  profileInfo: null,
  profile: null,
  ecddInfo: null,
  isAutoDeposit: false,
  newsFeed: [],
  onboarding: null,
  otp: null,
  smsOtp: null,
  smsOtpVerify: "OK",
  bankInfo: null,
  occupationList: null,
  businessNatureList: null,
  annualIncomeList: null,
  raceList: null,
  maritalStatusList: null,
  countryList: null,
  stateList: null,
  sourceOfFundList: null,
  sourceOfIncomeList: null,
  supportingDocs: null,
  transactionHistory: { data: [] },
  withdrawalResult: null,
  withdrawalBankList: [],
  withdrawalBankAccount: [],
  ppgToken: null,
  isFullRedemption: false,
  ecddStatus: null,
  isRejectAlert: false,
  goalIsExist: false,
  showError: false,
  goalSummary: {
    totalAsset: 0,
    totalReturn: 0,
  },
  goalInterest: null,
  isFailureAlert: false,
  maxAmount: null,
  isFirstOpenApp: true,
  eccdRequestLoading: false,
  cifPlanId: null,
  campaignList: { save: [], invest: [] },
  loadingCustomerGoals: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET_MAX_AMOUNT:
      return {
        ...state,
        maxAmount: null,
      };
    case RESET_GENERAL_SAVING:
      return {
        ...state,
        generalSavingDetails: null,
      };
    case RESET_CATEGORY_SAVING:
      return {
        ...state,
        savingGoalDetails: null,
      };
    case GET_WITHDRAWAL_RESULT:
      return {
        ...state,
        withdrawalResult: payload,
      };
    case SET_CIF_PLAN_ID:
      return {
        ...state,
        cifPlanId: payload,
      };
    case GET_CAMPAIGN:
      return {
        ...state,
        loadingCampaign: true,
      };
    case GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaignList: payload,
        loadingCampaign: false,
      };
    case GET_CAMPAIGN_FAILED:
      return {
        ...state,
        loadingCampaign: false,
      };
    case GET_GOAL_REQUEST:
      return {
        ...state,
        listGoalLoading: true,
      };

    case GET_GOAL_SUCCESS:
      return {
        ...state,
        listGoalLoading: false,
        goalList: payload,
      };

    case GET_GOAL_FAILURE:
      return {
        ...state,
        listGoalLoading: false,
        error: payload,
      };

    case GET_GOAL_PERIODS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_GOAL_PERIODS_SUCCESS:
      return {
        ...state,
        loading: false,
        goalPeriodList: payload,
      };

    case GET_GOAL_PERIODS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_INSTALLMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_INSTALLMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        installment: payload,
      };

    case GET_INSTALLMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_OCCUPATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_OCCUPATION_SUCCESS:
      return {
        ...state,
        loading: false,
        occupationList: payload,
      };

    case GET_OCCUPATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_BUSINESS_NATURE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_BUSINESS_NATURE_SUCCESS:
      return {
        ...state,
        loading: false,
        businessNatureList: payload,
      };

    case GET_BUSINESS_NATURE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_ANNUAL_INCOME_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ANNUAL_INCOME_SUCCESS:
      return {
        ...state,
        loading: false,
        annualIncomeList: payload,
      };

    case GET_ANNUAL_INCOME_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_RACE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_RACE_SUCCESS:
      return {
        ...state,
        loading: false,
        raceList: payload,
      };

    case GET_RACE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_MARITAL_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_MARITAL_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        maritalStatusList: payload,
      };

    case GET_MARITAL_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_COUNTRY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        countryList: payload,
      };

    case GET_COUNTRY_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_STATE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        stateList: payload,
      };

    case GET_STATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_SOURCE_OF_FUND_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_SOURCE_OF_FUND_SUCCESS:
      return {
        ...state,
        loading: false,
        sourceOfFundList: payload,
      };

    case GET_SOURCE_OF_FUND_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_SOURCE_OF_INCOME_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_SOURCE_OF_INCOME_SUCCESS:
      return {
        ...state,
        loading: false,
        sourceOfIncomeList: payload,
      };

    case GET_SOURCE_OF_INCOME_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_SUPPORTING_DOCS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_SUPPORTING_DOCS_SUCCESS:
      return {
        ...state,
        loading: false,
        supportingDocs: payload,
      };

    case GET_SUPPORTING_DOCS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GOAL_CREATION_REQUEST:
      return {
        ...state,
        loading: true,
        goalCreationLoading: true,
      };
    case CREATE_GOAL_RSP:
      return {
        ...state,
        loading: true,
        goalCreationRSPLoading: true,
      };
    case GOAL_CREATION_SUCCESS:
      return {
        ...state,
        loading: false,
        goalCreationLoading: false,
        goalCreationRSPLoading: false,
      };

    case GOAL_CREATION_FAILURE:
      return {
        ...state,
        loading: false,
        goalCreationLoading: false,
        goalCreationRSPLoading: false,
        error: payload,
      };

    case GET_CUSTOMER_GOALS_REQUEST:
      return {
        ...state,
        loading: true,
        loadingCustomerGoals: true,
      };

    case GET_CUSTOMER_GOALS_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingCustomerGoals: false,
        customerGoals: payload,
      };

    case GET_CUSTOMER_GOALS_FAILURE:
      return {
        ...state,
        loading: false,
        loadingCustomerGoals: false,
        error: payload,
      };

    case GET_CUSTOMER_GOAL_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_CUSTOMER_GOAL_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        customerGoalDetail: payload,
      };

    case GET_CUSTOMER_GOAL_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_NEWS_FEED_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_NEWS_FEED_SUCCESS:
      return {
        ...state,
        loading: false,
        newsFeed: payload,
      };

    case GET_NEWS_FEED_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_ONBOARDING_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ONBOARDING_SUCCESS:
      return {
        ...state,
        loading: false,
        onboarding: payload,
      };

    case GET_ONBOARDING_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_TRX_HISTORY_REQUEST:
      return {
        ...state,
        loadingTransactionHistory: true,
      };

    case GET_TRX_HISTORY_SUCCESS:
      return {
        ...state,
        loadingTransactionHistory: false,
        transactionHistory: payload,
      };

    case GET_TRX_HISTORY_FAILURE:
      return {
        ...state,
        loadingTransactionHistory: false,
        transactionHistory: { data: [] },
        error: payload,
      };

    case DEPOSIT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DEPOSIT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case DEPOSIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case WITHDRAWAL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case WITHDRAWAL_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawalResult: payload,
      };

    case WITHDRAWAL_FAILURE:
      return {
        ...state,
        loading: false,
        showError: true,
        error: payload,
      };

    case ECDD_REQUEST:
      return {
        ...state,
        eccdRequestLoading: true,
      };

    case ECDD_SUCCESS:
      return {
        ...state,
        eccdRequestLoading: false,
      };

    case ECDD_FAILURE:
      return {
        ...state,
        eccdRequestLoading: false,
        error: payload,
      };

    case GET_PPG_TOKEN_REQUEST:
      return {
        ...state,
        loadingPPGToken: true,
      };

    case GET_PPG_TOKEN_SUCCESS:
      return {
        ...state,
        loadingPPGToken: false,
      };

    case GET_PPG_TOKEN_FAILURE:
      return {
        ...state,
        loadingPPGToken: false,
        error: payload,
      };

    case GET_WITHDRAWAL_BANK_LIST_REQUEST:
      return {
        ...state,
        loadingWithdrawalBankList: true,
      };

    case GET_WITHDRAWAL_BANK_LIST_SUCCESS:
      return {
        ...state,
        loadingWithdrawalBankList: false,
        withdrawalBankList: payload,
      };

    case GET_WITHDRAWAL_BANK_LIST_FAILURE:
      return {
        ...state,
        loadingWithdrawalBankList: false,
        error: payload,
      };

    case GET_WITHDRAWAL_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        withdrawalBankAccountLoading: true,
      };

    case GET_WITHDRAWAL_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        withdrawalBankAccountLoading: false,
        withdrawalBankAccount: payload,
      };

    case GET_WITHDRAWAL_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        withdrawalBankAccountLoading: false,
        error: payload,
      };

    case SEND_OTP_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SEND_OTP_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        otp: payload,
      };

    case SEND_OTP_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case VERIFY_OTP_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case VERIFY_OTP_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case VERIFY_OTP_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loadingUpdateProfile: true,
        loading: true,
      };

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingUpdateProfile: false,
        profile: payload,
      };

    case UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        loadingUpdateProfile: false,
        error: payload,
      };

    case SEND_FEEDBACK_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SEND_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case SEND_FEEDBACK_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case CHECK_ECDD_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CHECK_ECDD_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        ecddStatus: payload,
      };

    case CHECK_ECDD_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case SMS_OTP_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SMS_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        smsOtp: payload,
      };

    case SMS_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case VERIFY_OTP_SMS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case VERIFY_OTP_SMS_SUCCESS:
      return {
        ...state,
        smsOtpVerify: payload,
        loading: false,
      };

    case VERIFY_OTP_SMS_FAILURE:
      return {
        ...state,
        loading: false,
        smsOtpVerify: payload,
        // error: payload
      };

    case SET_SAVING_TYPE:
      return {
        ...state,
        savingType: payload,
      };

    case SET_SELECTED_GOAL:
      return {
        ...state,
        selectedGoal: payload,
      };

    case SET_GENERAL_SAVING_DETAILS:
      return {
        ...state,
        generalSavingDetails: payload,
      };

    case SET_SAVING_GOAL_DETAILS:
      return {
        ...state,
        savingGoalDetails: payload,
      };

    case DELETE_SAVING_GOAL_FORM:
      return {
        ...state,
        savingGoalDetails: null,
        generalSavingDetails: null,
        installment: null,
        savingType: null,
      };

    case SHOW_ERROR:
      return {
        ...state,
        isModalOpen: true,
        errorMessage: payload,
      };

    case HIDE_ERROR:
      return {
        ...state,
        isModalOpen: false,
      };

    case SET_USER:
      return {
        ...state,
        isNewUser: OLD_USER,
      };

    case SET_PROFILE_INFO:
      return {
        ...state,
        profile: payload,
      };

    case ACTIVATE_AUTO_DEPOSIT:
      return {
        ...state,
        isAutoDeposit: true,
      };

    case SET_BANK_INFO:
      return {
        ...state,
        bankInfo: payload,
      };

    case SEND_CONTACT_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SEND_CONTACT_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case SEND_CONTACT_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case SET_FULL_REDEMPTION:
      return {
        ...state,
        isFullRedemption: payload,
      };

    case CLEAR_SMS_OTP:
      return {
        ...state,
        smsOtpVerify: "OK",
      };

    case SET_IS_LOADING:
      return {
        ...state,
        loading: payload,
      };

    case SET_REJECT_ALERT:
      return {
        ...state,
        isRejectAlert: payload,
      };

    case CHECK_GOAL_NAME_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CHECK_GOAL_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        goalIsExist: payload,
      };

    case CHECK_GOAL_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
        showError: true,
      };

    case SET_GOAL_IS_EXIST:
      return {
        ...state,
        goalIsExist: payload,
      };

    case GET_GOAL_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_GOAL_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        goalSummary: payload,
      };

    case GET_GOAL_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_GOAL_INTEREST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_GOAL_INTEREST_SUCCESS:
      return {
        ...state,
        loading: false,
        goalInterest: payload,
      };

    case GET_GOAL_INTEREST_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case SET_FAILURE_ALERT:
      return {
        ...state,
        isFailureAlert: payload,
      };

    case SET_SHOW_ERROR:
      return {
        ...state,
        showError: payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    case CHECK_WITHDRAWAL_MAX_AMOUNT_REQUEST:
      return {
        ...state,
        loadingMaxAmount: true,
      };

    case CHECK_WITHDRAWAL_MAX_AMOUNT_SUCCESS:
      return {
        ...state,
        loadingMaxAmount: false,
        maxAmount: payload,
      };

    case CHECK_WITHDRAWAL_MAX_AMOUNT_FAILURE:
      return {
        ...state,
        loadingMaxAmount: false,
        error: payload,
      };

    case RESET_DATA:
      return {
        ...initialState,
      };

    case GET_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        profile: payload,
        loading: false,
      };

    case GET_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case SET_IS_FIRST_OPEN_APP:
      return {
        ...state,
        isFirstOpenApp: payload,
      };
    case SET_PROFILE_INFOV2: {
      return {
        ...state,
        profile: payload,
        loading: false
      };
    }
    case RESET_PROFILE: {
      return {
        ...state,
        profile: null,
      };
    }
    case RESET_CUSTOMER_GOALS: {
      return {
        ...state,
        customerGoals: null,
      };
    }
    default:
      return state;
  }
};
