import { findAddress, handleNotExist } from "../onBoarding/utils";
import { COMPLETE_PAYLOAD } from "./indexInvest";
import { validateEmail as validEmail } from "../../utils/functions/validateEmail";

const PAYLOAD_FINANCE_PROFILE = "PAYLOAD_FINANCE_PROFILE";

let error = {
  errorAml: "",
};

const checkObjectValues = obj => {
  return Object.values(obj).every(value => value !== null && value !== "");
};

const checkProfileContacts = contacts => {
  if (!Array.isArray(contacts)) return false;

  const tempContact = contacts.find(item => item.contactTypeCd === "PERMANENT");
  if (!tempContact || !tempContact.state) return false;

  return findAddress(contacts, tempContact.state);
};

const checkProfileIdentities = (identities, occupation) => {
  if (!Array.isArray(identities)) return false;

  const whitelistOccupation = ["23", "62", "65", "90", "91"];

  const nric = identities.find(item => item.idTypeCd === "NRIC");

  const nricValid =
    nric &&
    nric.hasOwnProperty("idNo") &&
    nric.idNo &&
    nric.hasOwnProperty("citizenship") &&
    nric.citizenship;

  // first scenario where identities only contain nric (must onboard), e.g:
  // "identities": [
  //     {
  //         "idTypeCd": "NRIC",
  //         "idNo": "****",
  //         "citizenship": "MY"
  //     },
  // ]
  if (identities.length === 1 && nricValid) {
    // exception

    // tax is not available for these occupation (skip onboard)
    if (whitelistOccupation.includes(occupation)) return true;

    return false;
  }

  const tax = identities.find(identity => identity.idTypeCd === "TAX");

  const taxValid =
    tax &&
    tax.hasOwnProperty("idNo") &&
    tax.idNo &&
    tax.hasOwnProperty("citizenship") &&
    tax.citizenship;

  // second scenario where identities contains both nric and tax (skip onboard), e.g:
  //   "identities": [
  //     {
  //         "idTypeCd": "NRIC",
  //         "idNo": "****",
  //         "citizenship": "MY"
  //     },
  //    {
  //         "idTypeCd": "TAX",
  //         "idNo": "****",
  //         "citizenship": "NIGERIA"
  //     },
  // ],
  if (taxValid) {
    return true;
  }

  // exception
  // tax is not available for these occupation (skip onboard)
  if (whitelistOccupation.includes(occupation)) return true;

  return false;
};

const needOnboard = (profile, forPayload) => {
  const {
    natureOfBusinessCd,
    yearlyIncomeLevelCd,
    sourceOfFundCd,
    investmentObjCd,
    occupationTypeCd,
    race,
    contacts = [],
    identities = [],
  } = profile;

  let contactsPayload = checkProfileContacts(contacts);
  let identitiesPayload = checkProfileIdentities(identities, occupationTypeCd);
  if (!contactsPayload) return false;
  if (!identitiesPayload) return false;

  const financeProfileData = {
    natureOfBusinessCd: ["23", "91", "90", "62"].includes(occupationTypeCd)
      ? "99"
      : natureOfBusinessCd,
    yearlyIncomeLevelCd,
    sourceOfFundCd,
    investmentObjCd,
    occupationTypeCd,
    race,
    postCode: contactsPayload.postCode,
    state: contactsPayload.state,
    city: contactsPayload.city,
    country: contactsPayload.country,
    // because the address of each line doesn't know whether it will
    // contain contents or not, don't check the line address to avoid
    // empty string or null value validation
    // line1: contactsPayload.line1,
    // line2: contactsPayload.line2,
    // line3: contactsPayload.line3,
  };

  if (forPayload) {
    return financeProfileData;
  }

  return checkObjectValues(financeProfileData);
};
export const checkProfileRedirect = profile => {
  let tempProfile = {};

  if (Object.keys(profile).length > 0) {
    tempProfile = { ...profile };
  }

  const { amlHistory = [] } = tempProfile;

  if (amlHistory.length === 0) {
    if (
      needOnboard(profile) &&
      "pepFlag" in profile &&
      profile.pepFlag !== null
    )
      return COMPLETE_PAYLOAD;
    return "DIRECT_ONBOARDING";
  }

  if (amlHistory.length > 0) return "DIRECT_DASHBOARD";

  if ("amlHistory" in tempProfile) {
    error.errorAml = tempProfile.amlHistory;
  } else {
    error.errorAml = "NOT FOUND";
  }
  return error;
};

export const createPayload = profile => {
  const allKeys = [
    "natureOfBusinessCd",
    "occupationTypeCd",
    "yearlyIncomeLevelCd",
    "sourceOfFundCd",
    "investmentObjCd",
    "identities",
    "dob",
    "lang",
    "employerName",
    "fullName",
    "gender",
    "masterId",
    "mobileNo",
    "race",
    "contacts",
  ];
  const addressKeys = ["city", "country", "postCode", "state", "contactTypeCd"];
  const identitiesKeys = ["idTypeCd", "idNo", "citizenship"];

  for (let i = 0; i < allKeys.length; i++) {
    if (!profile.hasOwnProperty(allKeys[i])) return null;
  }

  for (let i = 0; i < identitiesKeys.length; i++) {
    if (!profile.identities[0].hasOwnProperty(identitiesKeys[i])) return null;
  }

  for (let i = 0; i < addressKeys.length; i++) {
    if (!profile.contacts[0].hasOwnProperty(addressKeys[i])) return null;
  }

  let resultPayloadAfterChecking = needOnboard(
    profile,
    PAYLOAD_FINANCE_PROFILE
  );

  const excludedProperties = [
    "idNo",
    "idTypeCd",
    "citizenship",
    "city",
    "state",
    "country",
    "postCode",
  ];
  const filteredResultPayload = Object.keys(resultPayloadAfterChecking)
    .filter(key => !excludedProperties.includes(key))
    .reduce((obj, key) => {
      obj[key] = resultPayloadAfterChecking[key];
      return obj;
    }, {});
  const {
    masterId,
    mobileNo,
    dob,
    fullName,
    gender,
    lang,
    maritalStatusCd,
    race,
    email,
    townOfBirth,
    countryOfBirth,
    identities,
    contacts,
    employerName,
  } = profile;

  if(!validEmail(email)) return "invalid-email";

  const payload = {
    masterId,
    mobileNo,
    dob,
    fullName,
    gender,
    employerName: employerName === "" ? "-" : employerName,
    lang,
    maritalStatusCd: handleNotExist(maritalStatusCd),
    race,
    email,
    townOfBirth: handleNotExist(townOfBirth),
    countryOfBirth: handleNotExist(countryOfBirth),
    identities: (identities || []).reduce((acc, curr) => {
      acc.push({
        citizenship: curr.citizenship,
        idTypeCd: curr.idTypeCd,
        idNo: curr.idNo,
      });
      return acc;
    }, []),
    address: checkProfileContacts(contacts),
    ...filteredResultPayload,
  };

  return payload;
};
