import {
  GET_GOAL_REQUEST,
  GET_GOAL_PERIODS_REQUEST,
  GET_INSTALLMENT_REQUEST,
  GOAL_CREATION_REQUEST,
  GET_CUSTOMER_GOALS_REQUEST,
  GET_CUSTOMER_GOAL_DETAIL_REQUEST,
  GET_NEWS_FEED_REQUEST,
  GET_ONBOARDING_REQUEST,
  GET_ANNUAL_INCOME_REQUEST,
  GET_BUSINESS_NATURE_REQUEST,
  GET_OCCUPATION_REQUEST,
  GET_RACE_REQUEST,
  GET_COUNTRY_REQUEST,
  GET_STATE_REQUEST,
  GET_MARITAL_STATUS_REQUEST,
  GET_SOURCE_OF_FUND_REQUEST,
  GET_SOURCE_OF_INCOME_REQUEST,
  GET_SUPPORTING_DOCS_REQUEST,
  GET_PPG_TOKEN_REQUEST,
  GET_WITHDRAWAL_BANK_LIST_REQUEST,
  SET_BANK_INFO,
  GET_TRX_HISTORY_REQUEST,
  DEPOSIT_REQUEST,
  WITHDRAWAL_REQUEST,
  ECDD_REQUEST,
  SEND_OTP_EMAIL_REQUEST,
  VERIFY_OTP_EMAIL_REQUEST,
  UPDATE_PROFILE_REQUEST,
  SET_SAVING_TYPE,
  SET_SELECTED_GOAL,
  SET_GENERAL_SAVING_DETAILS,
  SET_SAVING_GOAL_DETAILS,
  DELETE_SAVING_GOAL_FORM,
  SHOW_ERROR,
  HIDE_ERROR,
  SET_USER,
  SET_PROFILE_INFO,
  ACTIVATE_AUTO_DEPOSIT,
  SEND_CONTACT_MESSAGE_REQUEST,
  SET_FULL_REDEMPTION,
  SEND_FEEDBACK_REQUEST,
  CHECK_ECDD_STATUS_REQUEST,
  SMS_OTP_REQUEST,
  VERIFY_OTP_SMS_REQUEST,
  CLEAR_SMS_OTP,
  GET_WITHDRAWAL_BANK_ACCOUNT_REQUEST,
  SET_IS_LOADING,
  SET_REJECT_ALERT,
  CHECK_GOAL_NAME_REQUEST,
  SET_GOAL_IS_EXIST,
  GET_GOAL_SUMMARY_REQUEST,
  GET_GOAL_INTEREST_REQUEST,
  SET_FAILURE_ALERT,
  SET_SHOW_ERROR,
  CHECK_WITHDRAWAL_MAX_AMOUNT_REQUEST,
  RESET_DATA,
  CLEAR_ERROR,
  GET_CUSTOMER_REQUEST,
  SET_IS_FIRST_OPEN_APP,
  CREATE_GOAL_RSP,
  GET_CAMPAIGN,
  GET_CAMPAIGN_FAILED,
  GET_CAMPAIGN_SUCCESS,
  SET_CIF_PLAN_ID,
  GET_WITHDRAWAL_RESULT,
  RESET_CATEGORY_SAVING,
  RESET_GENERAL_SAVING,
  RESET_MAX_AMOUNT,
  RESET_CUSTOMER_GOALS,
  RESET_PROFILE,
} from "./constant";

export const resetMaxAmount = () => {
  return {
    type: RESET_MAX_AMOUNT,
  };
};

export const resetGeneralSaving = () => {
  return {
    type: RESET_GENERAL_SAVING,
  };
};

export const resetCategorySaving = () => {
  return {
    type: RESET_CATEGORY_SAVING,
  };
};

export const getGoals = () => {
  return {
    type: GET_GOAL_REQUEST,
  };
};

export const getGoalPeriods = () => {
  return {
    type: GET_GOAL_PERIODS_REQUEST,
  };
};

export const getInstallment = payload => {
  return {
    type: GET_INSTALLMENT_REQUEST,
    payload,
  };
};

export const createGoal = payload => {
  return {
    type: GOAL_CREATION_REQUEST,
    payload,
  };
};
export const createGoalRSP = (data, callback) => {
  return {
    type: CREATE_GOAL_RSP,
    payload: data,
    callback,
  };
};

export const depositRequest = payload => {
  return {
    type: DEPOSIT_REQUEST,
    payload,
  };
};

export const withdrawalRequest = payload => {
  return {
    type: WITHDRAWAL_REQUEST,
    payload,
  };
};

export const getWithdrawalResult = payload => {
  return {
    type: GET_WITHDRAWAL_RESULT,
    payload,
  };
};

export const ecddRequest = payload => {
  return {
    type: ECDD_REQUEST,
    payload,
  };
};

export const getPPGToken = data => {
  return {
    type: GET_PPG_TOKEN_REQUEST,
    payload: data,
  };
};

export const getWithdrawalBankList = payload => {
  return {
    type: GET_WITHDRAWAL_BANK_LIST_REQUEST,
    payload,
  };
};

export const getWithdrawalBankAccount = payload => {
  return {
    type: GET_WITHDRAWAL_BANK_ACCOUNT_REQUEST,
    payload,
  };
};

export const getCustomerGoals = callback => {
  return {
    type: GET_CUSTOMER_GOALS_REQUEST,
    callback,
  };
};

export const resetCustomerGoals = () => {
  return {
    type: RESET_CUSTOMER_GOALS,
  };
};

export const resetProfile = () => {
  return {
    type: RESET_PROFILE,
  };
};

export const getCustomerGoalDetail = payload => {
  return {
    type: GET_CUSTOMER_GOAL_DETAIL_REQUEST,
    payload,
  };
};

export const getNewsFeed = () => {
  return {
    type: GET_NEWS_FEED_REQUEST,
  };
};

export const getOnboarding = () => {
  return {
    type: GET_ONBOARDING_REQUEST,
  };
};

export const getOccupation = () => {
  return {
    type: GET_OCCUPATION_REQUEST,
  };
};

export const getBusinessNature = () => {
  return {
    type: GET_BUSINESS_NATURE_REQUEST,
  };
};

export const getAnnualIncome = () => {
  return {
    type: GET_ANNUAL_INCOME_REQUEST,
  };
};

export const getRace = () => {
  return {
    type: GET_RACE_REQUEST,
  };
};

export const getMaritalStatus = () => {
  return {
    type: GET_MARITAL_STATUS_REQUEST,
  };
};

export const getCountry = () => {
  return {
    type: GET_COUNTRY_REQUEST,
  };
};

export const getStateList = () => {
  return {
    type: GET_STATE_REQUEST,
  };
};

export const getSourceOfFund = () => {
  return {
    type: GET_SOURCE_OF_FUND_REQUEST,
  };
};

export const getSourceOfIncome = () => {
  return {
    type: GET_SOURCE_OF_INCOME_REQUEST,
  };
};

export const getSupportingDocs = () => {
  return {
    type: GET_SUPPORTING_DOCS_REQUEST,
  };
};

export const getTransactionHistory = payload => {
  return {
    type: GET_TRX_HISTORY_REQUEST,
    payload,
  };
};

export const sendEmailOtp = payload => {
  return {
    type: SEND_OTP_EMAIL_REQUEST,
    payload,
  };
};

export const verifyEmailOtp = payload => {
  return {
    type: VERIFY_OTP_EMAIL_REQUEST,
    payload,
  };
};

export const updateProfile = (payload, callback) => {
  return {
    type: UPDATE_PROFILE_REQUEST,
    payload,
    callback,
  };
};

export const sendContactMessage = payload => {
  return {
    type: SEND_CONTACT_MESSAGE_REQUEST,
    payload,
  };
};

export const sendFeedback = payload => {
  return {
    type: SEND_FEEDBACK_REQUEST,
    payload,
  };
};

export const setSavingType = payload => {
  return {
    type: SET_SAVING_TYPE,
    payload,
  };
};

export const setSelectedGoal = payload => {
  return {
    type: SET_SELECTED_GOAL,
    payload,
  };
};

export const setGeneralSavingDetails = payload => {
  return {
    type: SET_GENERAL_SAVING_DETAILS,
    payload,
  };
};

export const setSavingGoalDetails = payload => {
  return {
    type: SET_SAVING_GOAL_DETAILS,
    payload,
  };
};

export const deleteGoalForm = () => {
  return {
    type: DELETE_SAVING_GOAL_FORM,
  };
};

export const showError = payload => {
  return {
    type: SHOW_ERROR,
    payload,
  };
};

export const hideError = () => {
  return {
    type: HIDE_ERROR,
  };
};

export const setUser = () => {
  return {
    type: SET_USER,
  };
};

export const setProfileInfo = payload => {
  return {
    type: SET_PROFILE_INFO,
    payload,
  };
};

export const activateAutoDeposit = () => {
  return {
    type: ACTIVATE_AUTO_DEPOSIT,
  };
};

export const setBankInfo = payload => {
  return {
    type: SET_BANK_INFO,
    payload,
  };
};

export const setFullRedemption = payload => {
  return {
    type: SET_FULL_REDEMPTION,
    payload,
  };
};

export const checkEcddStatus = payload => {
  return {
    type: CHECK_ECDD_STATUS_REQUEST,
    payload,
  };
};

export const sendSMSOtp = payload => {
  return {
    type: SMS_OTP_REQUEST,
    payload,
  };
};

export const verifySMSOtp = (payload, withdrawalData) => {
  return {
    type: VERIFY_OTP_SMS_REQUEST,
    payload,
    withdrawalData,
  };
};

export const clearSMSOtp = () => {
  return {
    type: CLEAR_SMS_OTP,
  };
};

export const setIsLoading = payload => {
  return {
    type: SET_IS_LOADING,
    payload,
  };
};

export const setRejectAlert = payload => {
  return {
    type: SET_REJECT_ALERT,
    payload,
  };
};

export const checkGoalName = payload => {
  return {
    type: CHECK_GOAL_NAME_REQUEST,
    payload,
  };
};

export const setGoalIsExist = payload => {
  return {
    type: SET_GOAL_IS_EXIST,
    payload,
  };
};

export const getGoalSummary = payload => {
  return {
    type: GET_GOAL_SUMMARY_REQUEST,
    payload,
  };
};

export const getGoalInterest = payload => {
  return {
    type: GET_GOAL_INTEREST_REQUEST,
    payload,
  };
};

export const setFailureAlert = payload => {
  return {
    type: SET_FAILURE_ALERT,
    payload,
  };
};

export const setShowError = payload => {
  return {
    type: SET_SHOW_ERROR,
    payload,
  };
};

export const checkWithdrawalMaxAmount = (data, callback) => {
  return {
    type: CHECK_WITHDRAWAL_MAX_AMOUNT_REQUEST,
    payload: data,
    callback,
  };
};

export const resetData = () => {
  return {
    type: RESET_DATA,
  };
};

export const clearError = () => {
  return {
    type: CLEAR_ERROR,
  };
};

export const getCustomer = () => {
  return {
    type: GET_CUSTOMER_REQUEST,
  };
};

export const setIsFirstOpenApp = payload => {
  return {
    type: SET_IS_FIRST_OPEN_APP,
    payload,
  };
};

export const getCampaign = () => {
  return {
    type: GET_CAMPAIGN,
  };
};

export const getCampaignSuccess = payload => {
  return {
    type: GET_CAMPAIGN_SUCCESS,
    payload,
  };
};

export const getCampaignFailed = () => {
  return {
    type: GET_CAMPAIGN_FAILED,
  };
};

export const setCifPlanId = payload => {
  return {
    type: SET_CIF_PLAN_ID,
    payload,
  };
};
