import {
  GET_PROJECTED_RETURN,
  GET_PROJECTED_RETURN_SUCCESS,
  GET_PROJECTED_RETURN_FAILED,
  STEP_STARTED,
  GET_TOTAL_UNIT_FUNDS,
  GET_TOTAL_UNIT_FUNDS_SUCCESS,
  GET_TOTAL_UNIT_FUNDS_FAILED,
  PROJECTED_RETURN_INVEST,
  PROJECTED_RETURN_INVEST_SUCCESS,
  PROJECTED_RETURN_INVEST_FAILED,
  UPDATE_CUSTOMER_DUPLICATE,
  UPDATE_CUSTOMER_DUPLICATE_SUCCESS,
  UPDATE_CUSTOMER_DUPLICATE_FAILED,
} from "./constant";
import { ENV_SAVE } from "./constant";

const initialState = {
  startedStep: ENV_SAVE,
  totalUnitFunds: { itemCount: 16 },
  saveProjectedReturnValue: "3.95%",
  investProjectedReturnValue: "22.83%",
  investInceptionDate: "2024-04-30",
};

export default function getStartedReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CUSTOMER_DUPLICATE:
      return {
        ...state,
        updateCustomerDuplicateLoading: true,
      };
    case UPDATE_CUSTOMER_DUPLICATE_SUCCESS:
      return {
        ...state,
        updateCustomerDuplicateLoading: false,
      };
    case UPDATE_CUSTOMER_DUPLICATE_FAILED:
      return {
        ...state,
        updateCustomerDuplicateLoading: false,
      };
    case GET_PROJECTED_RETURN:
      return {
        ...state,
        getStartedLoading: true,
      };
    case GET_PROJECTED_RETURN_SUCCESS: {
      const cleaned = action?.payload?.value.replace(/\\/g, "");
      const MPSAVEData = JSON.parse(cleaned || "{}");
      return {
        ...state,
        saveProjectedReturnValue: MPSAVEData.projectedReturn,
        getStartedLoading: false,
      };
    }
    case GET_PROJECTED_RETURN_FAILED:
      return {
        ...state,
        saveProjectedReturnValue: "3.95%",
        getStartedLoading: false,
      };
    case STEP_STARTED:
      return {
        ...state,
        startedStep: action.payload,
      };
    case GET_TOTAL_UNIT_FUNDS:
      return {
        ...state,
        fundsUnitloading: true,
      };
    case GET_TOTAL_UNIT_FUNDS_SUCCESS:
      return {
        ...state,
        totalUnitFunds: action.payload,
        fundsUnitloading: false,
      };
    case GET_TOTAL_UNIT_FUNDS_FAILED:
      return {
        ...state,
        fundsUnitloading: false,
      };
    case PROJECTED_RETURN_INVEST:
      return {
        ...state,
        investGetStartedLoading: true,
      };
    case PROJECTED_RETURN_INVEST_SUCCESS: {
      const cleaned = action?.payload?.value.replace(/\\/g, "");
      const MPINVESTData = JSON.parse(cleaned || "{}");
      return {
        ...state,
        investProjectedReturnValue: MPINVESTData.projectedReturn,
        investInceptionDate: MPINVESTData.inceptionDate,
        investGetStartedLoading: false,
      };
    }
    case PROJECTED_RETURN_INVEST_FAILED:
      return {
        ...state,
        investGetStartedLoading: false,
      };
    default:
      return state;
  }
}
