import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "underscore";
import { Alert, DefaultLoader } from "../../components";
import { getNewTokenSuccess } from "../../containers/Authentication/redux/actions";
import { sendLogError } from "../../utils/api";
import { useHandleDuplicate } from "../../utils/hooks";
import { updateCustomerDuplicate } from "../getStarted/redux/actions";
import { checkProfileRedirect, createPayload } from "../getStarted/utils";
import { checkEcddStatus, updateProfile, resetData } from "../redux/action";
import { store } from "../../store";
import { getFundDetail } from "../fundSelection/redux/action";
import { DEEPLINK } from "../redux/constant";
const PRODUCT_TYPE = ["invest", "save"];
export const COMPLETE_PAYLOAD = "COMPLETE_PAYLOAD";
function DeeplinkLP(props) {
  const {
    history,
    match: { params },
    profile,
    token,
    loading,
    updateCustomerDuplicate,
    updateCustomerDuplicateLoading,
    getNewTokenSuccess,
    checkEcddStatus,
    getFundDetail,
    fundDetail,
    fundDetailLoading,
    updateProfile,
    resetData,
  } = props;
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [nricAlert, setOpenNricAlert] = useState(false);
  const [pepAlert, setPEPalert] = useState(false);

  const [status, setStatus] = useState("loading");
  const { duplicated, copywriting, handleDuplicate } = useHandleDuplicate(
    updateCustomerDuplicate,
    getNewTokenSuccess,
    () => {
      const latestProfile = store.getState().goal.profile;
      return latestProfile?.amlHistory?.length > 0
        ? history.push(`/deeplink/${params.type}/${params.code}`)
        : history.push("/onBoarding/form-step-one", {
            type: params.type,
            code: params.code,
          });
    }
  );

  const hasCalledEkyc = useRef(false);
  const isDuplicated =
    duplicated.idNo || duplicated.mobileNo || duplicated.name;

  const hasCalledGetFundDetail = useRef(false);

  const manipulateLogError = amlError => {
    let amlLogError;

    if (amlError && amlError.amlHistory && amlError.amlHistory.length > 0) {
      amlLogError = amlError.amlHistory[0].amlSts;
    }

    let errorPayload = {
      page: "deeplink",
      path: `/deeplink/status/${params.type}/${params.code}`,
      aml: amlLogError || "Not Found",
    };

    sendLogError(errorPayload);
  };

  const redirectAction = profile => {
    if (isDuplicated || nricAlert) return;

    let directTo = checkProfileRedirect(profile);
    if (typeof directTo === "object" && !Array.isArray(directTo)) {
      manipulateLogError(profile);

      history.push("/");
      return;
    }

    switch (directTo) {
      case "DIRECT_ONBOARDING":
        history.push("/onBoarding/form-step-one", {
          type: params.type,
          code: params.code,
        });
        break;
      case "DIRECT_DASHBOARD":
        if (params.type.toLowerCase() === "invest") {
          history.push("deeplink/invest-introduction", {
            type: params.type,
            code: params.code,
          });
        } else if (params.type.toLowerCase() === "save") {
          history.push(`/deeplink/${params.type}/${params.code}`);
        }
        break;
      case COMPLETE_PAYLOAD:
        if ("pepFlag" in profile && profile.pepFlag === "1") {
          setPEPalert(true);
          break;
        }
        const payload = createPayload(profile);

        if (typeof payload === "string" && payload === "invalid-email") {
          history.push("/onBoarding/form-step-one", {
            type: params.type,
            code: params.code,
            invalidEmail: true
          });
          break;
        }

        if (!isUpdatingProfile) {
          setIsUpdatingProfile(true);
          updateProfile(
            {
              payloadData: payload,
              from: {
                from: DEEPLINK,
                value: { type: params.type, code: params.code },
              },
            },
            () => setIsUpdatingProfile(false)
          );
        }
        break;
      default:
        manipulateLogError(profile);
        break;
    }
  };

  useEffect(() => {
    if (isUpdatingProfile) return;
    if (profile && token) {
      redirectAction(profile);

      if (profile?.amlHistory?.length > 0) {
        checkEcddStatus(profile.masterId);
      }
      return;
    }
  }, [profile, token, isUpdatingProfile]);

  useEffect(() => {
    if (token && params && params.type.toLowerCase() === "invest") {
      getFundDetail(params.code, () => {
        hasCalledGetFundDetail.current = true;
      });
    }
  }, [token, params]);

  useEffect(() => {
    if (hasCalledEkyc.current) return;

    if (!profile) {
      window.my.postMessage({
        api: "getAuthCode",
        scopes: ["auth_base", "auth_user", "USER_INFO_EKYC", "FIN_PROFILE"],
        event: "getAccessToken",
      });
      hasCalledEkyc.current = true;
      return;
    }

    const typeIsNotExist = !params.type;

    const productTypeIsNotExist = !PRODUCT_TYPE.includes(
      params.type.toLowerCase()
    );

    const fundIsNotExist =
      hasCalledGetFundDetail.current &&
      (!fundDetail ||
        (typeof fundDetail === "object" &&
          Object.values(fundDetail).length === 0));

    if (typeIsNotExist || productTypeIsNotExist || fundIsNotExist) {
      history.push("/");
      return;
    }
  }, [
    params,
    fundDetail,
    profile,
    hasCalledEkyc.current,
    hasCalledGetFundDetail.current,
  ]);

  useEffect(() => {
    if (loading || fundDetailLoading) {
      setStatus("loading");
    }
  }, [loading, fundDetailLoading]);

  useEffect(() => {
    window.eventManager.on("NRIC_VALIDATION", () => {
      setOpenNricAlert(true);
    });

    window.eventManager.on("EKYC_TERMINATED", () => {
      setStatus("ekyc-terminated");
    });

    window.eventManager.on("EKYC_LOADING_SHOWED", () => {
      setStatus("ekyc-showed");
    });

    return () => window.eventManager.clearEvents();
  }, []);

  if (status === "loading") return <DefaultLoader />;

  if (status === "ekyc-terminated") {
    history.push("/");
    return null;
  }

  return (
    <>
      <Alert
        title="Invalid ID format"
        description="We encountered an issue with the ID format that was automatically captured. "
        isOpen={nricAlert}
        isCS="https://principal.com.my"
        isCancel={false}
        btnTxt="Chat with us on WhatsApp"
        handleAction={() => {
          setOpenNricAlert(false);
          window.my.call("openAppLink", {
            url: "https://wa.me/60162996213",
          });
        }}
        handleCancel={() => setOpenNricAlert(false)}
        withCloseButton
      />
      <Alert
        title="Account doesn’t meet requirement"
        description="So sorry, your account doesn’t meet our requirement."
        isOpen={pepAlert}
        isCS="https://principal.com.my"
        isCancel={false}
        handleAction={() => {
          resetData();
          setPEPalert(false);
          history.push("/");
        }}
      />
      <Alert
        title={copywriting.heading}
        description={
          <>
            {copywriting.title}
            <br />
            <br />
            {copywriting.description}
            <br />
            <br />
            {copywriting.additionalContext}
            <span
              style={{
                marginTop: "1rem",
                marginBottom: "0.5rem",
                fontSize: "16px",
                fontWeight: 700,
                display: "block",
              }}
            >
              {duplicated.name && <>Name: {duplicated.name}</>}
              {duplicated.mobileNo && (
                <>
                  {duplicated.name && <br />}
                  Mobile: {duplicated.mobileNo}
                </>
              )}
              {duplicated.idNo && (
                <>
                  {(duplicated.name || duplicated.mobileNo) && <br />}
                  MyKad: {duplicated.idNo}
                </>
              )}
            </span>
          </>
        }
        additionalContent={
          duplicated.type === "tng" && (
            <p
              style={{
                color: "#1278CC",
                fontSize: "16px",
                fontWeight: 400,
                width: "100%",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                window.my.navigateTo({
                  url:
                    "/pages/link/link?url=" +
                    encodeURIComponent(
                      "https://support.tngdigital.com.my/hc/en-my/requests/new?ticket_form_id=360001558914"
                    ),
                });
              }}
            >
              Contact Touch ‘n Go Customer Service
            </p>
          )
        }
        isOpen={isDuplicated}
        handleAction={handleDuplicate}
        btnTxt={
          updateCustomerDuplicateLoading
            ? "Please wait..."
            : { mp: "Contact Customer Service", tng: "Confirm" }[
                duplicated.type
              ]
        }
        submitButtonDisabled={updateCustomerDuplicateLoading}
        titleStyle={{ fontWeight: 900 }}
      />
    </>
  );
}

const withConnect = connect(
  state => ({
    profile: state.goal.profile,
    loading: state.goal.loading,
    token: state.auth.MPtoken,
    fundDetail: state.fundSelectionReducer.fundDetailByCode,
    fundDetailLoading: state.fundSelectionReducer.fundDetailLoading,
    ecddStatus: state.goal.ecddStatus,
    updateCustomerDuplicateLoading:
      state.getStartedReducer.updateCustomerDuplicateLoading,
  }),
  {
    updateCustomerDuplicate,
    getNewTokenSuccess,
    checkEcddStatus,
    getFundDetail,
    resetData,
    updateProfile,
  }
);

export default compose(
  withRouter,
  withConnect
)(DeeplinkLP);
