import React from "react";
import { icons } from "../../constants";
import { stringDateToNumber } from "../../utils/functions/stringDateToNumber";
import { useCountDown } from "../../utils/hooks";
import "./style.scss";
import PropTypes from "prop-types";
import moment from "moment";
import seedImg from "../../assets/images/seed-plant.png";
import merdekaCampaign from "../../assets/images/bg-campaign-merdeka.png";

/**
 * @typedef {object} Campaign
 * @property {string} startDate
 * @property {string} endDate
 * @property {React.ReactNode} children
 * @property {() => void} onCashIn
 * @property {() => void} openPDF
 * @property {"rsp" | "lebaran" | "christmas" | "lunar"} type
 */

/**
 * Campaign component.
 *
 * @type {React.FC<Campaign>}
 * @returns {React.ReactElement}
 */
const CampaignCard = ({
  startDate,
  endDate,
  children,
  onCashIn,
  openPDF,
  type,
}) => {
  const now = new Date().getTime();
  const startDateInNum = stringDateToNumber(startDate);
  const timeCount = useCountDown(startDateInNum);

  if (type === "rsp") {
    return (
      <div className="c-card c-card--rsp">
        <img src={seedImg} alt="seed plant" />
        <div className="c-card__topbar c-card__topbar--rsp">
          <img src={icons.speaker} alt="Announcement icon" />
          <p className="c-card__link" onClick={openPDF}>
            FAQ
          </p>
        </div>
        {children}
      </div>
    );
  }

  if (type === "lebaran") {
    return (
      <div className={`c-card ${type && `c-card--${type}`}`}>
        <img src={merdekaCampaign} alt="Merdeka Campaign Promo" />
        <div className="c-card__container">
          <div className="c-card__topbar">
            <img src={icons.gift} alt="Campaign reward" />
            <p onClick={openPDF} className="c-card__link">
              Learn More
            </p>
          </div>
          {children}
          {now >= startDateInNum ? (
            <div className="c-card__end-section">
              {/* <p className="c-card__end-title">
                *Gross returns excluding fees{" "}
              </p> */}
              <button onClick={onCashIn} className="c-card__cash-in">
                Cash In
              </button>
            </div>
          ) : (
            <p className="c-card__countdown">
              Starting in: <span>{timeCount.days}</span> Day{" "}
              <span>{timeCount.hours}</span> hour{" "}
              <span>{timeCount.minutes}</span> min{" "}
              <span>{timeCount.seconds}</span> sec
            </p>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={`c-card ${type && `c-card--${type}`}`}>
      <div className="c-card__topbar">
        <div className="c-card__wrapper">
          <img src={icons.gift} alt="Campaign reward" />
          <p className="c-card__period-time">
            {moment(startDate).format("DD MMM YY")} -{" "}
            {moment(endDate).format("DD MMM YY")}
          </p>
        </div>
        <p onClick={openPDF} className="c-card__link">
          Learn More
        </p>
      </div>
      {children}
      {now >= startDateInNum ? (
        <div className="c-card__end-section">
          {/* <p className="c-card__end-title">*Gross returns excluding fees </p> */}
          <button onClick={onCashIn} className="c-card__cash-in">
            Cash In
          </button>
        </div>
      ) : (
        <p className="c-card__countdown">
          Starting in: <span>{timeCount.days}</span> Day{" "}
          <span>{timeCount.hours}</span> hour <span>{timeCount.minutes}</span>{" "}
          min <span>{timeCount.seconds}</span> sec
        </p>
      )}
    </div>
  );
};

CampaignCard.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onCashIn: PropTypes.func,
  openPDF: PropTypes.func,
};

export default CampaignCard;
