import {
  GET_FUND_LIST,
  GET_FUND_LIST_SUCCESS,
  GET_FUND_LIST_FAILED,
  GET_SCORE_LIST,
  GET_SCORE_LIST_SUCCESS,
  GET_SCORE_LIST_FAILED,
  SEND_FUNDS_CODE,
  SET_INVEST_MIN_AMOUNT,
  SELECTED_FUND_CUST,
  CREATE_FUND_V2,
  CREATE_FUND_SUCCESS_V2,
  CREATE_FUND_FAILED_V2,
  RESET_ERROR_CREATE_FUND,
  GET_FUND_DETAIL,
  GET_FUND_DETAIL_SUCCESS,
  GET_FUND_DETAIL_FAILED,
  SET_SELECTED_FUND_DETAIL,
} from "./constant";

const initialState = {
  fundList: [],
  fundListLoading: false,
  scoreList: [],
  scoreListError: "",
  fundsCode: [],
  minAmount: 10,
  fundCustSelected: {},
  errorCreateFund: false,
  createFundLoadingV2: false,
  fundDetailByCode: {},
  selectedFundDetail: null
};

export default function fundSelectionReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case GET_FUND_LIST:
      return {
        ...state,
        fundListLoading: true,
      };
    case GET_FUND_LIST_SUCCESS:
      return {
        ...state,
        fundList: payload,
        fundListLoading: false,
      };
    case GET_FUND_LIST_FAILED:
      return {
        ...state,
        fundList: [],
        fundListLoading: false,
      };
    case GET_SCORE_LIST:
      return {
        ...state,
        scoreListLoading: true,
      };
    case GET_SCORE_LIST_SUCCESS:
      return {
        ...state,
        scoreList: payload,
        scoreListLoading: false,
      };
    case GET_SCORE_LIST_FAILED:
      return {
        ...state,
        scoreList: [],
        scoreListError: payload,
        scoreListLoading: false,
      };
    case SEND_FUNDS_CODE:
      return {
        ...state,
        fundsCode: payload,
      };
    case SET_INVEST_MIN_AMOUNT:
      return {
        ...state,
        minAmount: payload,
      };
    case SELECTED_FUND_CUST:
      return {
        ...state,
        fundCustSelected: payload,
      };
    case CREATE_FUND_V2:
      return {
        ...state,
        createFundLoadingV2: true,
      };
    case CREATE_FUND_SUCCESS_V2:
      return {
        ...state,
        fundResult: payload,
        createFundLoadingV2: false,
      };
    case CREATE_FUND_FAILED_V2:
      return {
        ...state,
        errorCreateFund: true,
        createFundLoadingV2: false,
      };
    case RESET_ERROR_CREATE_FUND:
      return {
        ...state,
        errorCreateFund: false,
      };
    case GET_FUND_DETAIL:
      return {
        ...state,
        fundDetailLoading: true,
      };
    case GET_FUND_DETAIL_SUCCESS:
      return {
        ...state,
        fundDetailByCode: payload,
        fundDetailLoading: false,
      };
    case GET_FUND_DETAIL_FAILED:
      return {
        ...state,
        fundDetailLoading: false,
      };
    case SET_SELECTED_FUND_DETAIL:
      return { ...state, selectedFundDetail: payload };
    default:
      return state;
  }
}
